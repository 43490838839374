<div class="content-wrap">
	<div id="content" class="content">
        
        <h1 class="page-title " style="margin-bottom: 12.5px;">
            <span class="fw-semi-bold visible-xs-inline">Manage</span>
            <span class="fw-semi-bold" translate>Project</span>
        </h1>
        <div class="w100p manage_note">
            NOTE: To change these fields, please <a href="mailto:contact@arcskoru.com">contact us</a>. If the ownership of the project has changed, please fill our change of owner <a href="http://www.gbci.org/sites/default/files/change-of-owner.pdf" target="_blank">form</a> and send it along with your email.
        </div>
        <app-loader *ngIf="loading"></app-loader>
        <div class="static_info" [ngClass]="loading ? 'not-active' : ''">
            <div class="w50p">
                <div class="info">
                    <div class="title" translate>Project ID</div>
                    <div class="value">{{formdata.leed_id}}</div>
                </div>
                <div class="info">
                    <div class="title" translate>Project Address</div>
                    <div class="value">{{formdata.street}}</div>
                </div>
                <div class="info">
                    <div class="title" translate>Project City</div>
                    <div class="value">{{formdata.city}}</div>
                </div>
                <div class="info">
                    <div class="title" translate>Project State</div>
                    <div class="value">{{global.getState(formdata.state, formdata.country, true)}}</div>
                </div>
                <div class="info">
                    <div class="title" translate>Project Country/Region</div>
                    <div class="value">{{global.getCountry(formdata.country)}}</div>
                </div>
                <div class="info">
                    <div class="title" translate>Zipcode</div>
                    <div class="value">{{formdata.zip_code}}</div>
                </div>
                <div *ngIf="isRequired('station_type')" class="info">
                    <div class="title">{{structure[formdata.project_type].station_type}}</div>
                    <div class="value">{{formdata.station_type}}</div>
                </div>
            </div>
            
            <div *ngIf="global.IS_LO_city_comm()" class="w50p">
                <div *ngIf="isRequired('organization')" class="info">
                    <div class="title">{{structure[formdata.project_type].organization}}</div>
                    <div class="value">{{formdata.organization}}</div>
                </div>
                <div *ngIf="isRequired('owner_email')" class="info">
                    <div class="title">{{structure[formdata.project_type].owner_email}}</div>
                    <div class="value">{{formdata.owner_email}}</div>
                </div>
                <div *ngIf="isRequired('owner_name')" class="info">
                    <div class="title">{{structure[formdata.project_type].owner_name}}</div>
                    <div class="value">{{formdata.owner_name}}</div>
                </div>
                <div *ngIf="isRequired('manageEntityName')" class="info">
                    <div class="title">{{structure[formdata.project_type].manageEntityName}}</div>
                    <div class="value">{{formdata.manageEntityName}}</div>
                </div>
                <div *ngIf="isRequired('manageEntityAdd1')" class="info">
                    <div class="title">{{structure[formdata.project_type].manageEntityAdd1}}</div>
                    <div class="value">{{formdata.manageEntityAdd1}}</div>
                </div>
                <div *ngIf="isRequired('manageEntityAdd2')" class="info">
                    <div class="title">{{structure[formdata.project_type].manageEntityAdd2}}</div>
                    <div class="value">{{formdata.manageEntityAdd2}}</div>
                </div>
                <div *ngIf="isRequired('manageEntityCity')" class="info">
                    <div class="title">{{structure[formdata.project_type].manageEntityCity}}</div>
                    <div class="value">{{formdata.manageEntityCity}}</div>
                </div>
                <div *ngIf="isRequired('manageEntityCountry')" class="info">
                    <div class="title">{{structure[formdata.project_type].manageEntityCountry}}</div>
                    <div class="value">{{manageEntityCountry}}</div>
                </div>
                <div *ngIf="isRequired('manageEntityState')" class="info">
                    <div class="title">{{structure[formdata.project_type].manageEntityState}}</div>
                    <div class="value">{{global.getState(formdata.manageEntityState, formdata.manageEntityCountry, true)}}</div>
                </div>
            </div>
        
            <div *ngIf="!(formdata.project_type == 'city' || formdata.project_type == 'community')" class="w50p">
                <div class="info">
                    <div class="title" translate>Owner Organization</div>
                    <div class="value">{{formdata.organization}}</div>
                </div>
                <div class="info">
                    <div class="title" translate>Owner Email</div>
                    <div class="value">{{formdata.owner_email}}</div>
                </div>
                <div class="info">
                    <div class="title" translate>Owner Representative</div>
                    <div class="value">{{formdata.owner_name}}</div>
                </div>
                <div class="info">
                    <div class="title" translate>Owner Country/Region</div>
                    <div class="value">{{manageEntityCountry}}</div>
                </div>
            </div>
            
        </div>
        
        <div class="project_info" [ngClass]="loading || config.access != 'CRUD' ? 'not-active' : ''">
            <h4 translate>Project details</h4>
        
            <div id="details-form" class="form">
                <div *ngIf="isRequired('name')" class="group" [ngClass]="formdata_error.name.error ? 'has-error' : ''">      
                    <input maxlength="40" (input)="verifyField(formdata.name, 'name')" (blur)="submitDetails(formdata.name, 'name')" [(ngModel)]="formdata.name" name="name" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].name}}</label>
                    <span class="error">{{formdata_error.name.help}}</span>
                </div>
        
                <div *ngIf="isRequired('test_project')" class="group pl20">      
                    <div class="checkbox test_project pl0">
                        <input [(ngModel)]="formdata.test_project" (change)="validateInfo(formdata.test_project, 'test_project', true)" name="test_project" type="checkbox" value="test_project">                
                        <label for="checkbox" translate>
                            This is a test project 
                        </label>
                    </div>
                </div>
        
                <div *ngIf="isRequired('spaceType')" class="select-group group">   
                    <label>{{structure[formdata.project_type].spaceType}}</label>
                    <select (change)="submitDetails(formdata.spaceType, 'spaceType', true)" [(ngModel)]="formdata.spaceType" name="spaceType">
                        <option *ngFor="let type of spaceType" value="{{type}}">{{type}}</option>
                    </select>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                </div>
        
                <div *ngIf="isRequired('unitType')" class="select-group group">   
                    <label>{{structure[formdata.project_type].unitType}}</label>
                    <select (change)="submitDetails(formdata.unitType, 'unitType', true)" [(ngModel)]="formdata.unitType" name="unitType">
                        <option value="IP">square feet</option>
                        <option value="SI">square meters</option>
                    </select>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                </div>
        
                <div *ngIf="isRequired('ownerType')" class="select-group group">   
                    <label>{{structure[formdata.project_type].ownerType}}</label>
                    <select (change)="submitDetails(formdata.ownerType, 'ownerType', true)" [(ngModel)]="formdata.ownerType" name="ownerType">
                        <option *ngFor="let type of ownerType" value="{{type}}">{{type}}</option>
                    </select>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                </div>
        
                <div *ngIf="isRequired('year_constructed') && formdata.project_type != 'parksmart'" class="select-group group">   
                    <label>{{structure[formdata.project_type].year_constructed}}</label>   
                    <select (change)="submitDetails(formdata.year_constructed, 'year_constructed', true)" [(ngModel)]="formdata.year_constructed" name="year_constructed">
                        <option *ngFor="let type of year_constructed" value="{{type}}">{{type}}</option>
                    </select>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                </div>
        
                <div *ngIf="isRequired('year_constructed') && formdata.project_type == 'parksmart'" class="select-group group">   
                    <input id="year_constructed" maxlength="40" (input)="verifyField(formdata.year_constructed, 'year_constructed', true)" [(ngModel)]="formdata.year_constructed" name="year_constructed" type="text" [ngInit]="enableFdatePicker()" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].year_constructed}}</label>
                    <span class="error">{{formdata_error.year_constructed.help}}</span>
                </div>
        
                <div *ngIf="isRequired('noOfFloors')" class="group" [ngClass]="formdata_error.noOfFloors.error ? 'has-error' : ''">      
                    <input maxlength="40" (blur)="submitDetails(formdata.noOfFloors, 'noOfFloors')" (input)="verifyField(formdata.noOfFloors, 'noOfFloors')" [(ngModel)]="formdata.noOfFloors" name="noOfFloors" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].noOfFloors}}</label>
                    <span class="error">{{formdata_error.noOfFloors.help}}</span>
                </div>
        
                <div *ngIf="isRequired('annual_ridership')" class="group" [ngClass]="formdata_error.annual_ridership.error ? 'has-error' : ''">      
                    <input maxlength="40" (blur)="submitDetails(formdata.annual_ridership, 'annual_ridership')" (input)="verifyField(formdata.annual_ridership, 'annual_ridership')" [(ngModel)]="formdata.annual_ridership" name="annual_ridership" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].annual_ridership}}</label>
                    <span class="error">{{formdata_error.annual_ridership.help}}</span>
                </div>
                <div *ngIf="isRequired('full_time_staff')" class="group" [ngClass]="formdata_error.full_time_staff.error ? 'has-error' : ''">      
                    <input maxlength="40" (blur)="submitDetails(formdata.full_time_staff, 'full_time_staff')" (input)="verifyField(formdata.full_time_staff, 'full_time_staff')" [(ngModel)]="formdata.full_time_staff" name="full_time_staff" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].full_time_staff}}</label>
                    <span class="error">{{formdata_error.full_time_staff.help}}</span>
                </div>
                <div *ngIf="isRequired('time_spent_by_riders')" class="group" [ngClass]="formdata_error.time_spent_by_riders.error ? 'has-error' : ''">      
                    <input maxlength="40" (blur)="submitDetails(formdata.time_spent_by_riders, 'time_spent_by_riders')" (input)="verifyField(formdata.time_spent_by_riders, 'time_spent_by_riders')" [(ngModel)]="formdata.time_spent_by_riders" name="time_spent_by_riders" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].time_spent_by_riders}}
                        <span class="fontSize12 grey">(Max: <span class="fw-400">1,440</span>)
                        </span>
                    </label>
                    <span class="error">{{formdata_error.time_spent_by_riders.help}}</span>
                </div>
                <div *ngIf="isRequired('occupancy')" class="not-active group" [ngClass]="formdata_error.occupancy.error ? 'has-error' : ''">      
                    <span [hidden]="true">{{getTransitOccupancy()}}</span>
                    <input maxlength="40" [(ngModel)]="formdata.occupancy" name="occupancy" type="text" required readonly>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].occupancy}}</label>
                </div>
        
                <div *ngIf="isRequired('manageEntityName') && !global.IS_LO_city_comm()" class="group" [ngClass]="formdata_error.manageEntityName.error ? 'has-error' : ''">      
                    <input maxlength="40" (blur)="submitDetails(formdata.manageEntityName, 'manageEntityName')" (input)="verifyField(formdata.manageEntityName, 'manageEntityName')" [(ngModel)]="formdata.manageEntityName" name="manageEntityName" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].manageEntityName}}</label>
                    <span class="error">{{formdata_error.manageEntityName.help}}</span>
                </div>
                <div *ngIf="isRequired('manageEntityAdd1') && !global.IS_LO_city_comm()" class="group" [ngClass]="formdata_error.manageEntityAdd1.error ? 'has-error' : ''">      
                    <input maxlength="40" (input)="verifyField(formdata.manageEntityAdd1, 'manageEntityAdd1')" (blur)="submitDetails(formdata.manageEntityAdd1, 'manageEntityAdd1')" [(ngModel)]="formdata.manageEntityAdd1" name="manageEntityAdd1" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].manageEntityAdd1}}</label>
                    <span class="error">{{formdata_error.manageEntityAdd1.help}}</span>
                </div>
                <div *ngIf="isRequired('manageEntityAdd2') && !global.IS_LO_city_comm()" class="group" [ngClass]="formdata_error.manageEntityAdd2.error ? 'has-error' : ''">      
                    <input maxlength="40" (input)="verifyField(formdata.manageEntityAdd2, 'manageEntityAdd2')" (blur)="submitDetails(formdata.manageEntityAdd2, 'manageEntityAdd2')" [(ngModel)]="formdata.manageEntityAdd2" name="manageEntityAdd2" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].manageEntityAdd2}}</label>
                    <span class="error">{{formdata_error.manageEntityAdd2.help}}</span>
                </div>
                <div *ngIf="isRequired('manageEntityCity') && !global.IS_LO_city_comm()" class="group" [ngClass]="formdata_error.manageEntityCity.error ? 'has-error' : ''">      
                    <input maxlength="40" (input)="verifyField(formdata.manageEntityCity, 'manageEntityCity')" (blur)="submitDetails(formdata.manageEntityCity, 'manageEntityCity')" [(ngModel)]="formdata.manageEntityCity" name="manageEntityCity" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].manageEntityCity}}</label>
                    <span class="error">{{formdata_error.manageEntityCity.help}}</span>
                </div>
                <div *ngIf="isRequired('manageEntityCountry') && !global.IS_LO_city_comm()" class="select-group group">   
                    <label>{{structure[formdata.project_type].manageEntityCountry}}</label>
                    <select (change)="submitDetails(formdata.manageEntityCountry, 'manageEntityCountry', true)" [(ngModel)]="formdata.manageEntityCountry" name="manageEntityCountry">
                        <option *ngFor="let country of countries" value="{{country.code}}">{{country.name}}</option>
                    </select>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                </div>
                <div *ngIf="isRequired('manageEntityState') && !global.IS_LO_city_comm()" class="select-group group">   
                    <label>{{structure[formdata.project_type].manageEntityState}}</label>
                    <select (change)="submitDetails(formdata.manageEntityState, 'manageEntityState', true)" [(ngModel)]="formdata.manageEntityState" name="manageEntityState">
                        <option *ngFor="let state of states" value="{{state.code}}">{{state.name}}</option>
                    </select>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                </div>
        
                <div *ngIf="isRequired('IsResidential')" class="radio_buttons mb30">
                    <span class="head">{{structure[formdata.project_type].IsResidential}}</span>
                    <div class="flex mt8">
                        <div class="mr25">
                            <span class="checkcontainer" (click)="submitDetails(formdata.IsResidential, 'IsResidential', true)">
                                Yes
                                <input type="radio" name="IsResidential" [value]="true" [(ngModel)]="formdata.IsResidential">
                                <span class="radiobtn"></span>
                            </span>
                        </div>
        
                        <div>
                            <span class="checkcontainer" (click)="submitDetails(formdata.IsResidential, 'IsResidential', true)">
                                No
                                <input type="radio" name="IsResidential" [value]="false" [(ngModel)]="formdata.IsResidential">
                                <span class="radiobtn"></span>
                            </span>
                        </div>
                    </div>
                </div>
        
                <div *ngIf="isRequired('noOfResUnits') && formdata.IsResidential" class="group" [ngClass]="formdata_error.noOfResUnits.error ? 'has-error' : ''">      
                    <input maxlength="9" (input)="verifyField(formdata.noOfResUnits, 'noOfResUnits')" (blur)="submitDetails(formdata.noOfResUnits, 'noOfResUnits')" [(ngModel)]="formdata.noOfResUnits" name="noOfResUnits" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].noOfResUnits}}</label>
                    <span class="error">{{formdata_error.noOfResUnits.help}}</span>
                </div>
        
                <div *ngIf="isRequired('AffiliatedHigherEduIns')" class="radio_buttons mb30">
                    <span class="head">{{structure[formdata.project_type].AffiliatedHigherEduIns}}</span>
                    <div class="flex mt8">
                        <div class="mr25">
                            <span class="checkcontainer" (click)="submitDetails(formdata.AffiliatedHigherEduIns, 'AffiliatedHigherEduIns', true)">
                                Yes
                                <input type="radio" name="AffiliatedHigherEduIns" [value]="true" [(ngModel)]="formdata.AffiliatedHigherEduIns">
                                <span class="radiobtn"></span>
                            </span>
                        </div>
        
                        <div>
                            <span class="checkcontainer" (click)="submitDetails(formdata.AffiliatedHigherEduIns, 'AffiliatedHigherEduIns', true)">
                                No
                                <input type="radio" name="AffiliatedHigherEduIns" [value]="false" [(ngModel)]="formdata.AffiliatedHigherEduIns">
                                <span class="radiobtn"></span>
                            </span>
                        </div>
                    </div>
                </div>
        
                <div *ngIf="isRequired('nameOfSchool') && formdata.AffiliatedHigherEduIns" class="group" [ngClass]="formdata_error.nameOfSchool.error ? 'has-error' : ''">      
                    <input maxlength="40" (input)="verifyField(formdata.nameOfSchool, 'nameOfSchool')" (blur)="submitDetails(formdata.nameOfSchool, 'nameOfSchool')" [(ngModel)]="formdata.nameOfSchool" name="nameOfSchool" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].nameOfSchool}}</label>
                    <span class="error">{{formdata_error.nameOfSchool.help}}</span>
                </div>
        
                <div *ngIf="isRequired('noOfParkingSpace')" class="group" [ngClass]="formdata_error.noOfParkingSpace.error ? 'has-error' : ''">      
                    <input maxlength="40" (input)="verifyField(formdata.noOfParkingSpace, 'noOfParkingSpace')" (blur)="submitDetails(formdata.noOfParkingSpace, 'noOfParkingSpace')" [(ngModel)]="formdata.noOfParkingSpace" name="noOfParkingSpace" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].noOfParkingSpace}}</label>
                    <span class="error">{{formdata_error.noOfParkingSpace.help}}</span>
                </div>
                <div *ngIf="isRequired('noOfParkingLevels')" class="group" [ngClass]="formdata_error.noOfParkingLevels.error ? 'has-error' : ''">      
                    <input maxlength="40" (input)="verifyField(formdata.noOfParkingLevels, 'noOfParkingLevels')" (blur)="submitDetails(formdata.noOfParkingLevels, 'noOfParkingLevels')" [(ngModel)]="formdata.noOfParkingLevels" name="noOfParkingLevels" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].noOfParkingLevels}}</label>
                    <span class="error">{{formdata_error.noOfParkingLevels.help}}</span>
                </div>
                <div *ngIf="isRequired('projectWebsite')" class="group" [ngClass]="formdata_error.projectWebsite.error ? 'has-error' : ''">      
                    <input maxlength="40" (input)="verifyField(formdata.projectWebsite, 'projectWebsite')" (blur)="submitDetails(formdata.projectWebsite, 'projectWebsite')" [(ngModel)]="formdata.projectWebsite" name="projectWebsite" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].projectWebsite}}</label>
                    <span class="error">{{formdata_error.projectWebsite.help}}</span>
                </div>
                <div *ngIf="isRequired('projectInfo')" class="group" [ngClass]="formdata_error.projectInfo.error ? 'has-error' : ''">      
                    <input maxlength="500" (input)="verifyField(formdata.projectInfo, 'projectInfo')" (blur)="submitDetails(formdata.projectInfo, 'projectInfo', true)" [(ngModel)]="formdata.projectInfo" name="projectInfo" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].projectInfo}}</label>
                    <span class="error">{{formdata_error.projectInfo.help}}</span>
                </div>
                <div *ngIf="isRequired('gross_area')" class="group pb20" [ngClass]="formdata_error.gross_area.error ? 'has-error' : ''">      
                    <input maxlength="40" (input)="verifyField(formdata.gross_area, 'gross_area')" (blur)="submitDetails(formdata.gross_area, 'gross_area')" [(ngModel)]="formdata.gross_area" name="gross_area" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].gross_area}}
                        <span class="fontSize12 grey">(Max: <span class="fw-400">{{global.addCommas(global.getMax(formdata.unitType, formdata.project_type))}}</span> {{global.getUnit(formdata.unitType, formdata.project_type)}})
                        </span>
                    </label>
                    <span class="error">{{formdata_error.gross_area.help}}</span>
                </div>
                <div *ngIf="formdata.project_type == 'parksmart'" class="mb30">
                    <p class="" translate>
                        Please upload images of your parking structure (be sure to include exterior photos and images of sustainable amenities within your garage)
                    </p>
                    <input #elem (input)="global.fileNameChanged(elem)" class="hide uploadFile" type="file" accept="image/*" multiple>
                        <button [ngClass]="{'deactivateSelect': config.access != 'CRUD'}" class="btn btn-primary mt10 viewUpload laptop floatNone" (click)="global.openUploadDialog('laptop', 'project-structure-images', 'S3')">
                            <i class="fa fa-upload" aria-hidden="true"></i>
                            <span class="pl5 pr5" translate>Upload</span>					
                        </button>
                        <div class="pt10">
                            <div *ngIf="config.currentUpload">
                                <span class='uploadLoaderSpinner'></span>
                                <span [innerHtml]="config.currentUploadName"></span>
                            </div>
                            <div *ngFor="let uploaded_file of config.uploaded_files" class="flex">
                                <a class="anchor_blue_color" (click)="global.downloadFile(uploaded_file)"><span class="hover_underline">{{global.getFileName(uploaded_file)}}</span></a>
                                <div (click)="global.deleteFile(uploaded_file, 'project-structure-images')" class="fa-trash-alt">
                                    <span id="project-structure-images" title="Delete file" [ngClass]="{'deactivateSelect': config.access != 'CRUD'}" class='ml10 far fa-trash-alt cursor-pointer cursor-pointer'></span>
                                </div>
                            </div>
                        </div>
                </div>
        
                <div *ngIf="isRequired('confidential')" class="radio_buttons mb40">
                    <span class="head">{{structure[formdata.project_type].confidential}}</span>
                    <div class="flex mt8">
                        <div class="mr25">
                            <span class="checkcontainer" (click)="submitDetails(formdata.confidential, 'confidential', true)">
                                Yes
                                <input type="radio" name="confidential" [value]="true" [(ngModel)]="formdata.confidential">
                                <span class="radiobtn"></span>
                            </span>
                        </div>
        
                        <div>
                            <span class="checkcontainer" (click)="submitDetails(formdata.confidential, 'confidential', true)">
                                No
                                <input type="radio" name="confidential" [value]="false" [(ngModel)]="formdata.confidential">
                                <span class="radiobtn"></span>
                            </span>
                        </div>
                    </div>
                </div>
        
            </div>
        
            <h4 translate>Certification details</h4>
            
            <div id="details-form" class="form">
                <div *ngIf="isRequired('OtherCertProg')" class="select-group group">   
                    <label>{{structure[formdata.project_type].OtherCertProg}}</label>   
                    <select (change)="submitDetails(formdata.OtherCertProg, 'OtherCertProg', true)" [(ngModel)]="formdata.OtherCertProg" name="OtherCertProg">
                        <option *ngFor="let type of OtherCertProg" value="{{type}}">{{type}}</option>
                    </select>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                </div>
        
                <div *ngIf="isRequired('IsLovRecert')" class="radio_buttons mb30">
                    <span class="head">{{structure[formdata.project_type].IsLovRecert}}</span>
                    <div class="flex mt8">
                        <div class="mr25">
                            <span class="checkcontainer" (click)="submitDetails(formdata.IsLovRecert, 'IsLovRecert', true)">
                                Yes
                                <input type="radio" name="IsLovRecert" [value]="true" [(ngModel)]="formdata.IsLovRecert">
                                <span class="radiobtn"></span>
                            </span>
                        </div>
        
                        <div>
                            <span class="checkcontainer" (click)="submitDetails(formdata.IsLovRecert, 'IsLovRecert', true)">
                                No
                                <input type="radio" name="IsLovRecert" [value]="false" [(ngModel)]="formdata.IsLovRecert">
                                <span class="radiobtn"></span>
                            </span>
                        </div>
                    </div>
                </div>
        
                <div *ngIf="isRequired('PrevCertProdId') && formdata.IsLovRecert" class="group" [ngClass]="formdata_error.PrevCertProdId.error ? 'has-error' : ''">      
                    <input (input)="verifyField(formdata.PrevCertProdId, 'PrevCertProdId')" (blur)="submitDetails(formdata.PrevCertProdId, 'PrevCertProdId', true)" [(ngModel)]="formdata.PrevCertProdId" name="PrevCertProdId" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].PrevCertProdId}}</label>
                    <span class="error">{{formdata_error.PrevCertProdId.help}}</span>
                </div>
        
                <div *ngIf="isRequired('intentToPrecertify')" class="radio_buttons mb30">
                    <span class="head">{{structure[formdata.project_type].intentToPrecertify}}</span>
                    <div class="flex mt8">
                        <div class="mr25">
                            <span class="checkcontainer" (click)="submitDetails(formdata.intentToPrecertify, 'intentToPrecertify', true)">
                                Yes
                                <input type="radio" name="intentToPrecertify" [value]="true" [(ngModel)]="formdata.intentToPrecertify">
                                <span class="radiobtn"></span>
                            </span>
                        </div>
        
                        <div>
                            <span class="checkcontainer" (click)="submitDetails(formdata.intentToPrecertify, 'intentToPrecertify', true)">
                                No
                                <input type="radio" name="intentToPrecertify" [value]="false" [(ngModel)]="formdata.intentToPrecertify">
                                <span class="radiobtn"></span>
                            </span>
                        </div>
                    </div>
                </div>
        
                <div *ngIf="isRequired('targetCertDate')" class="group" [ngClass]="formdata_error.targetCertDate.error ? 'has-error' : ''">      
                    <input class="date_field" max="M dd, yyyy" id="targetCertDate" [(ngModel)]="formdata.targetCertDate" name="targetCertDate" type="text" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>{{structure[formdata.project_type].targetCertDate}}</label>
                    <span class="error">{{formdata_error.targetCertDate.help}}</span>
                </div>
            </div>
        
            <h4 class="mb10">Tags</h4>
        
            <div id="details-form" class="form">
                <div class="group">      
                    <input name="tags">
                    <span *ngIf="error_in_tags" class="tag_error">{{error_in_tags_text}}</span>
                </div>
            </div>
        </div>
        <div class="modal fade portfolio-modal-wbg ofac_invalid_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    
                    <div class="modal-header">
                        <span class="fw-600 fs16" translate>Owner organization is blocked</span>
                    </div>
                    <div class="modal-body pl25 mb20">
                        <p class="fw-400" translate>
                            This project is located in a sanctioned country or belongs to a sanctioned entity 
                            according to the sanctions administered/enforced by OFAC, the U.S. Department of State, 
                            or the United Nations Security Council. Please <a href="mailto:legal@gbci.org" target="_top">
                            contact us</a> for more details.
                        </p>  
                        </div>
                    <div class="modal-footer">
                        <button class="floatl btn btn-primary mr10" (click)="resetOrganizationDetails()" translate>Continue</button>
                        <button type="button" class="floatl btn btn-default" (click)="resetOrganizationDetails()" translate>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade portfolio-modal-wbg review_not_allowed_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-md"><div class="modal-content"><div class="p2535 ">
            
            <div class="left-right-align mb20">
                <h4 class="mb10 mt10 fw-600" translate>Change to Test Project not allowed</h4> 
            </div>
        
            <div class="left-right-align fs16">
                <div class="danger-color">
                    <div translate>Project has pending payments/invoices.</div>
                </div>
            </div>
        
            <div class="mt30">
                <div class="align-left">
                    <button data-dismiss="modal" type="button" class="btn btn-default btn-md" translate>CLOSE</button>
                </div>
            </div>
        </div></div></div>
        </div>

    </div>
</div>
